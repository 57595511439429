import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import FaceBookIcon from './FaceBookIcon';
import TwitterIcon from './TwitterIcon';
import BraveStrap from './BraveStrap';

const Footer = () => (
	<StaticQuery
		query={graphql`
			query {
				prismicGlobals {
					data {
						company_description {
							text
						}
						contact_details {
							html
						}
						physical_address {
							html
						}
						facebook_link {
							url
						}
						twitter_link {
							url
						}
					}
				}
			}
		`}
		render={(data) => (
			<footer className="pl4 pr4 pl5-l pr5-l bg-black pt4 pb4 pt5-l pb5-l white">
				<div className="flex-l justify-between">
					<div className="f4 f3-l measure yellow lh-copy w-100 w-30-l mb4">
						{data.prismicGlobals.data.company_description.text}
					</div>
					<div className="w-100 w-60-l flex-l justify-between lh-copy items-top">
						<div
							className="f5 measure"
							dangerouslySetInnerHTML={{ __html: data.prismicGlobals.data.physical_address.html }}
						/>
						<div className="f5 measure">
							<div
								dangerouslySetInnerHTML={{ __html: data.prismicGlobals.data.contact_details.html }}
							/>
							<ul className="list mt3 flex pl0">
								<li className="mr3">
									<a href={data.prismicGlobals.data.facebook_link.url}>
										<FaceBookIcon />
									</a>
								</li>
								<li>
									<a href={data.prismicGlobals.data.twitter_link.url}>
										<TwitterIcon />
									</a>
								</li>
							</ul>
						</div>
						<div className="footer-logo mt4 mb4 mt0-l mb0-l" />
					</div>
				</div>
				<div className="bt flex-l tc tl-l b--white-50 grey justify-between f6 items-center pt3 footer-grey mt4-l lh-copy">
					<div>
						<Link to="/compliance" className="no-underline">
							Compliance &amp; Certifications
						</Link>
					</div>
					<div className="mt2 mb2">
						&copy;{new Date().getFullYear()} Natural Sugars, All Rights Reserved
					</div>
					<BraveStrap />
				</div>
			</footer>
		)}
	/>
);

export default Footer;
