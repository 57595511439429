import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import BraveHamburger from './BraveHamburger';
import Menu from './Menu';
import ServiceMenu from './ServiceMenu';
import Footer from './Footer';
import '../css/normalize.css';
import 'tachyons';
import '../css/main.css';

const Layout = ({ children }) => {
  const [isOpen, toggle] = useState(false);
  return (
    <div>
      <div className={isOpen ? 'no-scroll' : 'not-active'} menuState={isOpen}>
        <header className="pl4 pr4 pl5-l pr5-l pt2 pb2 pt4-l pb4-l z-9999 absolute white top-0 w-100">
          <div className="bg-white w-100 db header-border mb3" />
          <div className="w-100 flex justify-between items-center">
            <Link to="/" className="logo db ml4-l" />
            <div className="menu-area flex justify-end items-center">
              <ServiceMenu menuState={isOpen} />
              <div className="mr4-l pointer" onClick={() => toggle(!isOpen)}>
                <BraveHamburger menuState={isOpen} />
              </div>
            </div>
          </div>
        </header>
        <Menu classNames={isOpen ? 'menu-active' : ''} menuState={isOpen} />
        <main>{children}</main>
        <Footer />
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
