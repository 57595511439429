import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { useSpring, animated } from 'react-spring';

const Menu = ({ menuState }) => {
  const { x } = useSpring({
    x: menuState ? 1 : 0
  });

  return (
    <animated.div
      className="fixed top-0 left-0 bg-yellow h-100 w-100 black menu z-999 flex items-center pl4 pr4 pl5-l pr5-l"
      style={{
        pointerEvents: menuState ? 'all' : 'none',
        opacity: x.interpolate(x => x)
      }}
    >
      {/* Products Menu */}
      <StaticQuery
        query={graphql`
          query {
            allPrismicService(
              filter: { tags: { nin: "Content-Model" } }
              sort: { fields: [data___page_weight], order: ASC }
            ) {
              edges {
                node {
                  uid
                  data {
                    service_name {
                      text
                    }
                    page_weight
                  }
                }
              }
            }
            allPrismicCompanyPage(
              filter: { tags: { nin: "Content-Model" } }
              sort: { fields: [data___page_weight], order: ASC }
            ) {
              edges {
                node {
                  uid
                  data {
                    page_title {
                      text
                    }
                    page_weight
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div className="w-100 pl4-l pr4-l flex-l justify-between mt5 mw8 center">
            <div className="w-100 w-50-l">
              <div className="f6 semi-bold white ttu tracked">Our Products</div>
              <ul className="mt3 list f-menu black pl0 g-medium lh-copy nav">
                {data.allPrismicService.edges.map(serviceItem => (
                  <li>
                    <Link
                      to={`${serviceItem.node.uid}/`}
                      className="link black"
                      activeClassName="active"
                    >
                      {serviceItem.node.data.service_name.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            {/* Company Menu */}
            <div className="w-100 w-40-l">
              <div className="f6 semi-bold white ttu tracked">Our Company</div>
              <ul className="mt3 list f-menu black pl0 g-medium lh-copy nav">
                {data.allPrismicCompanyPage.edges.map(menuItem => (
                  <li>
                    <Link
                      to={`${menuItem.node.uid}/`}
                      className="link black"
                      activeClassName="active"
                    >
                      {menuItem.node.data.page_title.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      />
    </animated.div>
  );
};

export default Menu;
