import React from 'react';
import { useSpring, animated, config } from 'react-spring';

const BraveHamburger = ({ menuState }) => {
  const style = {
    overflow: 'visible',
    cursor: 'pointer',
    position: 'relative',
    fontSize: '2em',
    // disable touch highlighting on devices
    WebkitTapHighlightColor: 'rgba(0,0,0,0)'
  };
  const { x, y, color } = useSpring({
    x: menuState ? 1 : 0,
    y: menuState ? 0 : 1,
    color: menuState ? '#000' : '#fff',
    config: config.wobbly
  });
  return (
    <svg viewBox="0 0 96 96" height="1em" style={style}>
      <g
        id="navicon"
        fill="none"
        stroke="currentColor"
        strokeWidth="14"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animated.line
          x1="7"
          y1="26"
          x2="89"
          y2="26"
          transform={x.interpolate(x => `translate(${x * 12}, ${x * -7}) rotate(${x * 45}, 7, 26)`)}
          style={{
            color: color
          }}
        />
        <animated.line
          x1="7"
          y1="70"
          x2="89"
          y2="70"
          transform={x.interpolate(x => `translate(${x * 12}, ${x * 7}) rotate(${x * -45}, 7, 70)`)}
          style={{
            color: color
          }}
        />
        <animated.line
          x1="7"
          y1="48"
          x2="89"
          y2="48"
          transform={x.interpolate(x => `translate(${x * -96})`)}
          opacity={y}
        />
      </g>
    </svg>
  );
};

export default BraveHamburger;
